import { jsxs, Fragment, jsx } from "react/jsx-runtime";
import { Style } from "@tecnobit-srl/styles-manager";
import "@tecnobit-srl/ui-spinner/css";
import { join, filter } from "lodash-es";
function Spinner({ color, size, className }) {
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Style, { style: "core-ui/spinner" }),
    /* @__PURE__ */ jsx(
      "div",
      {
        className: join(filter([`spinner`, size, className]), " "),
        "data-color": color ?? "accent"
      }
    )
  ] });
}
export {
  Spinner
};
